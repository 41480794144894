@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --white-1: white;
  --white-2: rgba(255, 255, 255, 0.7);
  --white-3: rgba(255, 255, 255, 0.5);

  --black-1: black;
  --black-2: rgba(25, 26, 35, 1);

  --purple-1: rgba(185, 44, 220, 1);
}
* {
  box-sizing: border-box;
  font-style: normal;
  font-family: "Inter", sans-serif;
  color: var(--white-1);
}
body {
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  margin: unset;
  background-color: var(--black-1);
}
a {
  text-decoration: none;
}
button,
input,
select,
textarea {
  appearance: none;
  -webkit-appearance: none;
  border: unset;
  background-color: transparent;
  margin: unset;
  padding: unset;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.section {
  width: 100%;
  position: relative;
}
.container {
  max-width: 1440px;
  position: relative;
  width: 100%;
  margin: auto;
  padding: 0 120px;
}
.cursor {
  cursor: pointer;
}

.flex-r {
  display: flex;
  flex-direction: row;
}
.flex-r.v-center {
  align-items: center;
}
.flex-r.v-top {
  align-items: flex-start;
}
.flex-r.v-btm {
  align-items: flex-end;
}
.flex-r.h-center {
  justify-content: center;
}
.flex-r.h-left {
  justify-content: start;
}
.flex-r.h-right {
  justify-content: end;
}
.flex-r.just-space {
  justify-content: space-between;
}
.flex-c {
  display: flex;
  flex-direction: column;
}
.flex-c.h-center {
  align-items: center;
}
.flex-c.h-left {
  align-items: flex-start;
}
.flex-c.h-right {
  align-items: flex-end;
}
.flex-c.v-center {
  justify-content: center;
}
.flex-c.v-top {
  justify-content: start;
}
.flex-c.h-btm {
  justify-content: end;
}

.t-400 {
  font-weight: 400;
}
.t-500 {
  font-weight: 500;
}
.t-600 {
  font-weight: 600;
}
.t-700 {
  font-weight: 700;
}
.t-800 {
  font-weight: 800;
}

.t-14-19 {
  font-size: 14px;
  line-height: 140%;
}
.t-16-22 {
  font-size: 16px;
  line-height: 140%;
}
.t-18-25 {
  font-size: 18px;
  line-height: 140%;
}
.t-20-28 {
  font-size: 20px;
  line-height: 140%;
}
.t-22-33 {
  font-size: 22px;
  line-height: 150%;
}
.t-24-33 {
  font-size: 24px;
  line-height: 140%;
}
.t-32-41 {
  font-size: 32px;
  line-height: 130%;
}
.t-40-52 {
  font-size: 40px;
  line-height: 130%;
}
.t-48-57 {
  font-size: 48px;
  line-height: 120%;
}

.t-center {
  text-align: center;
}
.t-right {
  text-align: right;
}
.t-left {
  text-align: left;
}

@media all and (min-width: 1280px) {
}
@media all and (min-width: 1445px) {
  .container {
    max-width: 100%;
    margin: unset;
  }
}

@media all and (max-width: 1140px) {
}
@media all and (max-width: 1080px) {
  .container {
    padding: 0 60px;
  }
}
@media all and (max-width: 994px) {
  .container {
    padding: 0 40px;
  }
}
@media all and (max-width: 972px) {
}
@media all and (max-width: 768px),
  all and (orientation: landscape) and (max-width: 768px) {
  .m-hide {
    display: none;
  }
  .m-c {
    flex-direction: column;
  }
}
@media all and (max-width: 568px) {
  .container {
    padding: 0 20px;
  }
}
@media all and (max-width: 430px) {
}
@media all and (max-width: 320px) {
}

.purpleButton {
  @apply p-3 text-white font-semibold rounded-lg text-center cursor-pointer w-full;
  background-color: var(--purple-1);
}

.warningMessage {
  @apply p-3 text-red-500 font-bold text-center w-full;
}
