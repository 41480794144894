.backgroundThankYou {
  background-color: var(--black-2);
  background-image: url("./background-thank-you.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 75%;
}

@media (min-width: 1024px) {
  .backgroundThankYou {
    background-color: var(--black-2);
    background-image: url("./background-thank-you.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
  }
}
