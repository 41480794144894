.Instructions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;
  padding: 1rem;
}

.Video {
  position: fixed;
  left: 0;
  right: 0;
  min-width: 100vw;
  min-height: 100vh;
  object-fit: cover;
  z-index: -1;
}

.Container {
  background-color: #191a23;
  border-radius: 4px;
  padding: 1rem;
}

.Button {
  width: 100%;
}

.nav {
  padding-top: 24px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}
.nav > div > img {
  max-width: 410.7px;
  width: 100%;
}
.nav > div > a {
  padding: 16px 24px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 1);
}

.main {
  padding-top: 128px;
  min-height: 100vh;
}
.main > video {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 0;
  filter: brightness(0.5);
}
.background {
  background-color: var(--black-2);
  background-image: url("./background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.main > img {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 0;
  filter: brightness(0.5);
}
.btm-section {
  margin-top: 50px;
}
.btm-section > div {
  flex-wrap: wrap;
  justify-content: flex-end;
}
.btm-section > div > div {
  width: 100%;
  max-width: 206px;
  margin-left: 17px;
}
.accord > .accord-head > img {
  transform: rotate(-180deg);
  transition: transform 200ms ease-out;
}
.accord.active > .accord-head > img {
  transform: rotate(0deg);
}

@media all and (min-width: 1280px) {
}
@media all and (min-width: 1440px) {
}

@media all and (max-width: 1140px) {
}
@media all and (max-width: 1080px) {
}
@media all and (max-width: 994px) {
}
@media all and (max-width: 972px) {
}
@media all and (max-width: 768px),
  all and (orientation: landscape) and (max-width: 768px) {
  .btm-section > div {
    flex-wrap: unset;
    margin-top: 50px;
  }
  .background {
    background: none;
    background-color: var(--black-2);
  }
}
@media all and (max-width: 568px) {
  .btm-section > div {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .btm-section > div > div {
    width: 100%;
    max-width: 100%;
    margin-left: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    text-align: center;
  }
}
@media all and (max-width: 430px) {
}
@media all and (max-width: 320px) {
}
